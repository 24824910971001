exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-about-js": () => import("./../../../src/pages/articles/about.js" /* webpackChunkName: "component---src-pages-articles-about-js" */),
  "component---src-pages-articles-contact-js": () => import("./../../../src/pages/articles/contact.js" /* webpackChunkName: "component---src-pages-articles-contact-js" */),
  "component---src-pages-articles-intro-js": () => import("./../../../src/pages/articles/intro.js" /* webpackChunkName: "component---src-pages-articles-intro-js" */),
  "component---src-pages-articles-work-js": () => import("./../../../src/pages/articles/work.js" /* webpackChunkName: "component---src-pages-articles-work-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */)
}

